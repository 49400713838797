<template>
  <v-container
    id="dashboard"
    fluid
    tag="section"
  >
    <v-row justify="center">
      <v-col
        cols="12"
        md="10"
      >
        <base-material-card color='#174282'>
          <template v-slot:heading>
            <div
              class="display-2"
            >
              Vos inscriptions
            </div>
          </template>
          <v-card-actions>
            <v-row>
              <v-col
                offset-md=3
                md=3
                offset=4
                cols=8
              >
                <v-btn href="#conferences"
                class="primary">
                  Voir les ateliers
                </v-btn>
              </v-col>
              <!--<v-col
                md=3
                offset-md=0
                offset=4
                cols=8
              >
                <v-btn href="#consultations"
                class="primary">
                  Voir les consultations
                </v-btn>
              </v-col>-->
            </v-row>
          </v-card-actions>
          <v-row id="conferences">
            <v-col
              cols="11"
              offset="1"
            >
              <h2>Ateliers</h2>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-card
                class="mx-auto my-12"
                max-width="500"
              >
                <v-card-title class="justify-center">Jeudi 30 septembre</v-card-title>
                <v-divider class="mx-4"></v-divider>
                <div v-if="!conferences.jeudi.filter(function(item){ return item.subCount.includes(userId.toString())}).length">
                  <v-card-text>
                    Aucune inscription pour cette date.
                  </v-card-text>
                </div>
                <div
                  v-for="conference in conferences.jeudi.filter(function(item){ return item.subCount.includes(userId.toString())})"
                  :key="conference.id"
                >
                  <v-card-text>
                    <h4>{{ conference.name }}</h4>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn
                      text
                      color="red"
                      @click="unSubcribeToConf (conference.id)"
                      v-if="userId && conference.subCount.includes(userId.toString())"
                    >
                      Se desinscrire de cette atelier
                    </v-btn>
                  </v-card-actions>
                  <v-divider class="mx-4"></v-divider>
                </div>
              </v-card>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-card
                class="mx-auto my-12"
                max-width="500"
              >
                <v-card-title class="justify-center">Vendredi 1 octobre</v-card-title>
                <v-divider class="mx-4"></v-divider>
                <div v-if="!conferences.vendredi.filter(function(item){ return item.subCount.includes(userId.toString())}).length">
                  <v-card-text>
                    Aucune inscription pour cette date.
                  </v-card-text>
                </div>
                <div
                  v-for="conference in conferences.vendredi.filter(function(item){ return item.subCount.includes(userId.toString())})"
                  :key="conference.id"
                >
                  <v-card-text>
                    <h4>{{ conference.name }}</h4>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn
                      text
                      color="red"
                      @click="unSubcribeToConf (conference.id)"
                      v-if="userId && conference.subCount.includes(userId.toString())"
                    >
                      Se desinscrire de cet atelier
                    </v-btn>
                  </v-card-actions>
                  <v-divider class="mx-4"></v-divider>
                </div>
              </v-card>
            </v-col>
          </v-row>
          <!--<v-row id="consultations">
            <v-col
              cols="11"
              offset="1"
            >
              <h2>Consultations</h2>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-card
                class="mx-auto my-12"
                max-width="500"
              >
                <v-card-title>jeudi 30 septembre</v-card-title>
                <v-divider class="mx-4"></v-divider>
                <div v-if="!consultations.filter(function(item){ return item.Date === '2020-10-05' && item.USR_ID === userId.toString()}).length">
                  <v-card-text>
                    Aucune inscription pour cette date.
                  </v-card-text>
                </div>
                <div
                  v-for="consultation in consultations.filter(function(item){ return item.Date === '2020-10-05' && item.USR_ID === userId.toString() })"
                  :key="consultation.id"
                >
                  <v-card-text>
                    <h3>{{ consultation.TimeStart.split(':')[0] }}H{{ consultation.TimeStart.split(':')[1] }}-{{ consultation.TimeEnd.split(':')[0] }}H{{ consultation.TimeEnd.split(':')[1] }}</h3>
                    <br>
                    <p>{{ consultation.Description }}</p>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn
                      text
                      color="#174282"
                      @click="subcribeToConsultation (consultation.FFC_ID)"
                      v-if="userId && consultation.USR_ID != userId"
                    >
                      Réserver cette consultation
                    </v-btn>
                    <v-btn
                      text
                      color="red"
                      @click="unSubcribeToConsultation (consultation.FFC_ID)"
                      v-else-if="userId && consultation.USR_ID == userId"
                    >
                      Annuler cette consultation
                    </v-btn>
                    <v-btn
                      text
                      color="#174282"
                      v-else
                      @click="openModal"
                    >
                      Connexion requise pour réserver
                    </v-btn>
                  </v-card-actions>
                  <v-divider class="mx-4"></v-divider>
                </div>
              </v-card>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-card
                class="mx-auto my-12"
                max-width="500"
                v-if="consultations"
              >
                <v-card-title>vendredi 1 octobre</v-card-title>
                <v-divider class="mx-4"></v-divider>
                <div v-if="!consultations.filter(function(item){ return item.Date === '2020-10-06' && item.USR_ID === userId.toString()}).length">
                  <v-card-text>
                    Aucune inscription pour cette date.
                  </v-card-text>
                </div>
                <div
                  v-for="consultation in consultations.filter(function(item){ return item.Date === '2020-10-06' && item.USR_ID === userId.toString() })"
                  :key="consultation.id"
                >
                  <v-card-text>
                    <h3>{{ consultation.TimeStart.split(':')[0] }}H{{ consultation.TimeStart.split(':')[1] }}-{{ consultation.TimeEnd.split(':')[0] }}H{{ consultation.TimeEnd.split(':')[1] }}</h3>
                    <br>
                    <p>{{ consultation.Description }}</p>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn
                      text
                      color="#174282"
                      @click="subcribeToConsultation (consultation.FFC_ID)"
                      v-if="userId && consultation.USR_ID != userId"
                    >
                      Réserver cette consultation
                    </v-btn>
                    <v-btn
                      text
                      color="red"
                      @click="unSubcribeToConsultation (consultation.FFC_ID)"
                      v-else-if="userId && consultation.USR_ID == userId"
                    >
                      Annuler cette consultation
                    </v-btn>
                    <v-btn
                      text
                      color="#174282"
                      @click="openModal"
                      v-else
                    >
                      Connexion requise pour réserver
                    </v-btn>
                  </v-card-actions>
                  <v-divider class="mx-4"></v-divider>
                </div>
              </v-card>
            </v-col>
          </v-row>-->
        </base-material-card>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
    >
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          X
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
  import { httpGet, httpPost } from '@/plugins/axios'
  export default {
    name: 'Profile',

    data () {
      return {
        snackbar: false,
        text: '',
        render: 0,
        timeout: 2000,
        modal: false,
        userId: '',
        consultations: null,
        element: 'conferences',
        conferences: {
          jeudi: [
            {
              id: 1,
              name: '08h30-10h00 Choisir mes associés et ma forme juridique : 2 piliers clés de mon entreprise',
              description: 'Votre Avocat vous conseille dans le choix de la structure et de vos partenaires en fonction de votre activité et de vos objectifs.',
              maximum: 80,
              subCount: [],
              intervenants: '',
            },
            {
              id: 2,
              name: '10h00-11h30 Handicap et droit pour enrichir l\'entreprise',
              description: 'Au-delà du nouveau dispositif du 1er janvier pour promouvoir l\'emploi des travailleurs en situation de handicap ,l\'apport du handicap dans l\'entreprise.',
              maximum: 80,
              subCount: [],
              intervenants: '',
            },
            {
              id: 3,
              name: '11h30-13h00 Quelle stratégie de financement pour accélérer mon développement ?',
              description: 'L\'Avocat vous conseille sur les différentes solutions de financement au regard de la situation particulière et des projets de votre entreprise.',
              maximum: 80,
              subCount: [],
              intervenants: '',
            },
            {
              id: 4,
              name: '13h00-14h30 Cybersécurité',
              description: 'Le RGPD est aussi un outil de sécurisation et de développement que votre Avocat vous aidera à mettre en place et à faire évoluer. ',
              maximum: 80,
              subCount: [],
              intervenants: '',
            },
            {
              id: 5,
              name: '14h30-16h00 La fiducie, ou reine des sûretés',
              description: 'L\'Avocat a le monopole aux côtés des établissements de crédit de la fiducie outil indispensable à l\'emprunteur pour obtenir plus et à des meilleures conditions',
              maximum: 80,
              subCount: [],
              intervenants: '',
            },
            {
              id: 6,
              name: '16h00-17h30 La reprise d\'entreprises à la barre ',
              description: 'Les avantages, les inconvénients et les risques expliqués par votre Avocat.',
              maximum: 80,
              subCount: [],
              intervenants: '',
            },
          ],
          vendredi: [
            {
              id: 7,
              name: '08h30-10h00 Penser le conflit autrement',
              description: 'Présentation des différents modes de règlements amiables des différends.',
              maximum: 80,
              subCount: [],
              intervenants: '',
            },
            {
              id: 8,
              name: '10h00-11h30 Prévention et nouvelle procédure de traitement de sortie de crise',
              description: 'Quand et comment les utiliser pour prévenir les risques et sortir de la crise ?',
              maximum: 80,
              subCount: [],
              intervenants: '',
            },
            {
              id: 9,
              name: '11h30-13h00 Mon bail commercial',
              description: 'Le bail commercial est un contrat essentiel que votre Avocat sécurisera.',
              maximum: 80,
              subCount: [],
              intervenants: '',
            },
            {
              id: 10,
              name: '13h00-14h30 La gestion de crise ',
              description: 'L\'Avocat vous accompagne pour mettre en place un plan de crise.',
              maximum: 80,
              subCount: [],
              intervenants: '',
            },
            {
              id: 11,
              name: '14h30-16h00 Patrimoine du dirigeant ',
              description: 'Panel des différentes protections à mettre en œuvre pour le chef d\'entreprise.',
              maximum: 80,
              subCount: [],
              intervenants: '',
            },
            {
              id: 12,
              name: '16h00-17h30 Télétravail',
              description: 'Avantages du télétravail riment avec risques à sécuriser avec votre Avocat.',
              maximum: 80,
              subCount: [],
              intervenants: '',
            },
          ],
        },
      }
    },
    mounted () {
      this.getData()
    },
    methods: {
      openModal () {
        this.modal = true
      },
      async getData () {
        const consultations = await httpGet('getConsultations')
        console.log(consultations)
        this.consultations = consultations.Consultations
        this.userId = this.$store.state.userId
        this.conferences.jeudi.forEach(
          async (conference) => {
            const users = await httpPost('getSubByConfId', {
              confId: conference.id,
            })
            conference.subCount = []
            users.Count.forEach(user => {
              conference.subCount.push(user.USR_ID)
            })
          },
        )
        this.conferences.vendredi.forEach(
          async (conference) => {
            const users = await httpPost('getSubByConfId', {
              confId: conference.id,
            })
            conference.subCount = []
            users.Count.forEach(user => {
              conference.subCount.push(user.USR_ID)
            })
          },
        )
      },
      displaySnack (message) {
        this.text = message
        this.snackbar = true
      },
      async subcribeToConf (id, max) {
        const response = await httpPost('subToConf', {
          userId: this.$store.state.userId,
          confId: id,
          max: max,
        })
        console.log(response)
        let message = 'Vous êtes bien enregistré pour cet atelier.'
        if (response.Error) {
          message = response.Error
        }
        this.displaySnack(message)
        this.getData()
      },
      async unSubcribeToConf (id) {
        const response = await httpPost('unSubToConf', {
          userId: this.$store.state.userId,
          confId: id,
        }).then(this.getData())
        var message = 'Votre réservation pour cet atelier a bien été annulée.'
        if (response.Error) {
          message = response.Error
        }
        this.displaySnack(message)
        this.getData()
      },
      async subcribeToConsultation (id) {
        await httpPost('subToConsultation', {
          userId: this.$store.state.userId,
          consultationId: id,
        })
        this.getData()
        this.displaySnack('Vous avez reservé cette consultation avec succés.')
      },
      async getSubByConf (id) {
        await httpPost('getSubByConfId', {
          confId: id,
        })
      },
      async unSubcribeToConsultation (id) {
        await httpPost('unSubToConsultation', {
          consultationId: id,
        })
        this.getData()
        this.displaySnack('Votre réservation pour cette consultation a bien été annulée.')
      },
    },
  }
</script>
